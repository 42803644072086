








































@use "@/styles/variables/colors";
@use "@/styles/variables/grid";

$animation-duration: 800ms;

@keyframes form-skeleton-loading {
    0% {
        background-color: colors.$grey-1;
    }

    100% {
        background-color: colors.$grey-2;
    }
}

.form-skeleton {
    padding-bottom: 0;
    border-bottom: none;
    margin-bottom: 0;
}

.form-skeleton-item {
    margin-bottom: grid.$space-5;
}

.form-skeleton-item__label,
.form-skeleton-item__input {
    animation: $animation-duration linear infinite alternate form-skeleton-loading;
}

.form-skeleton-item__label {
    width: 40%;
    height: grid.$space-2;
    margin-bottom: grid.$space-1;
}

.form-skeleton-item__input {
    .form-skeleton-item--short & {
        height: grid.$space-6;
    }

    .form-skeleton-item--tall & {
        height: grid.$space-10 + grid.$space-4;
    }
}
