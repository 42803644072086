

















































































@use "@/styles/variables/typography";
@use "@/styles/variables/colors";

.button-icon,
a.button-icon {
    --color: #{colors.$generaptor-brand};
    --color-text: #{colors.$secondary-grey};

    position: relative;
    display: inline-flex;
    box-sizing: border-box;
    padding: 0;
    max-width: 100%;
    background: transparent;
    border: 0;
    color: var(--color-text);
    font-family: typography.$font-family;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.01em;
    white-space: nowrap;
    text-decoration: none;
    text-overflow: ellipsis;
    cursor: pointer;
    flex-shrink: 0;
    appearance: none;

    &:hover {
        --color: #{colors.$accent};
        --color-text: #{colors.$accent};
    }

    &:active {
        --color: #{colors.$accent-dark};
        --color-text: #{colors.$accent-dark};
    }

    &--underline {
        text-decoration: underline;
        font-size: 16px;
    }

    &--accent-color {
        --color: #{colors.$accent};
        --color-text: #{colors.$accent};

        &:hover {
            --color: #{colors.$accent-light};
            --color-text: #{colors.$accent-light};
        }

        &:active {
            --color: #{colors.$accent-dark};
            --color-text: #{colors.$accent-dark};
        }
    }
}

.button-icon__icon {
    display: flex;
    color: var(--color);
    margin-right: 8px;
}

.button-icon:disabled,
.button-icon--disabled {
    --color: #{colors.$secondary-grey-light};
    --color-text: #{colors.$secondary-grey-light};

    pointer-events: none;
}
