















































































































































































@use "@/styles/mixins/breakpoint" as *;
@use "@/styles/variables/colors";
@use "@/styles/variables/grid";

.explainer__headline {
    margin-bottom: grid.$space-8;

    @include breakpoint(md) {
        margin-bottom: 15px;
    }
}

.explainer__description {
    margin-bottom: grid.$space-4;
}

.explainer__steps {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 64px 90px;

    @include breakpoint(md) {
        grid-gap: 52px;
    }

    @include breakpoint(sm) {
        display: block;
    }
}

.explainer__step {
    @include breakpoint(sm) {
        margin-bottom: grid.$space-8;
    }
}

.explainer__step-illustration {
    margin-bottom: grid.$space-5;
    border: 1px solid var(--primary-color-alpha);
    border-radius: grid.$space-1;
    overflow: hidden;
    box-shadow: 0 9px 15px rgba(178 181 190 / 29%);

    img {
        display: block;
        max-height: 195px;
        max-width: 100%;
        margin: auto;
    }

    @include breakpoint(md) {
        margin-bottom: grid.$space-2;
    }
}

.explainer__step-title {
    position: relative;
    padding-left: 57px;
    margin-bottom: 11px;

    @include breakpoint(md) {
        margin-bottom: 8px;
        padding-left: 0;
    }
}

.explainer__step-number {
    position: absolute;
    left: 0;
    top: calc(-72px / 4);
    width: 47px;
    height: 72px;
    color: colors.$accent;
    font-size: 72px;
    font-weight: 500;
    line-height: 72px;
    text-align: center;
    opacity: 0.2;

    @include breakpoint(md) {
        display: none;
    }
}

.explainer__step-title-text {
    font-size: grid.$space-3;
    color: colors.$accent;
    letter-spacing: -0.03em;
    font-weight: 500;
}

.explainer__step-descriptor {
    padding-left: 57px;
    color: colors.$secondary-grey;
    line-height: 1.5;

    @include breakpoint(md) {
        padding-left: 0;
    }
}

.navigation-wrapper {
    position: relative;
    padding-right: grid.$space-6;
}

.keen-slider {
    overflow: visible;
}

.dots {
    display: flex;
    padding: 10px 0;
}

.dot {
    position: relative;
    padding: 0;
    margin: 0;
    margin-right: 24px;
    width: 22px;
    height: 22px;
    flex-shrink: 0;
    background: colors.$white;
    border-radius: 50%;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    color: colors.$generaptor-brand;
    border: 1px solid colors.$generaptor-brand;
    font-size: 12px;
    appearance: none;
}

.dot:last-child {
    margin: 0;
}

.dot:not(:last-child)::after {
    content: "";
    position: absolute;
    width: 24px;
    height: 2px;
    background-color: rgba(132 165 120 / 6%);
    top: calc(50% - 1px);
    left: calc(100% + 1px);
}

.dot:focus {
    outline: none;
}

.dot.active {
    background: colors.$generaptor-brand;
    color: colors.$white;
}
