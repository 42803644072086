


























































































































@use "@/styles/mixins/breakpoint";
@use "@/styles/mixins/hover" as *;
@use "@/styles/mixins/reset" as *;
@use "@/styles/variables/colors";
@use "@/styles/variables/grid";
@use "@/styles/variables/typography";

%icon {
    display: block;
    width: 24px;
    height: 24px;
    margin-right: grid.$space-2;
    flex-shrink: 0;
}

$text-color: colors.$text;

.user-menu {
    color: $text-color;
}

.user-menu__dropdown {
    @include breakpoint.mobile {
        margin-top: -#{grid.$space-5};
    }

    @include breakpoint.desktop {
        width: 220px;
    }
}

.user-menu__avatar {
    &.user-menu__avatar--main {
        @extend %button-reset;

        display: block;
        width: 45px;
        height: 45px;
    }

    &.user-menu__avatar--list {
        @extend %icon;

        @include breakpoint.mobile {
            width: 32px;
            height: 32px;
            margin: 0 0 grid.$space-1;
        }
    }
}

.user-menu__item {
    $item-padding: 0 grid.$space-2;

    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    padding: $item-padding;

    &.user-menu__header {
        @include breakpoint.mobile {
            height: auto;
            flex-direction: column;
            margin-bottom: grid.$space-5;
        }

        @include breakpoint.desktop {
            margin-bottom: 10px;
        }
    }

    &.user-menu__item--action {
        cursor: pointer;

        @include hover {
            background: colors.$generaptor-brand-bg;
        }

        &::before {
            @extend %icon;

            content: "";
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    &.user-menu__item--button {
        @extend %button-reset;

        padding: $item-padding;
        text-align: left;
    }

    &.user-menu__item--link {
        color: $text-color;
        text-decoration: none;
    }

    &.user-menu__item--cases {
        &::before {
            background-image: url("@/assets/suitcase.svg");
        }
    }

    &.user-menu__item--prefs {
        &::before {
            background-image: url("@/assets/knobs.svg");
        }
    }

    &.user-menu__item--signout {
        margin-top: 10px;
        border-top: 1px solid colors.$secondary-grey-light;

        &::before {
            background-image: url("@/assets/exit.svg");
        }
    }

    &:not(.user-menu__header, .user-menu__item--signout) {
        font-size: typography.$font-size-smaller;
    }
}

.user-menu__item__text {
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
}
