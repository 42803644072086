@use "./mixins/breakpoint" as *;
@use "./variables/colors";

.formular {
    grid-column: span 8;
}

.formular__teaser {
    margin-bottom: 48px;
    font-size: 18px;
    line-height: 1.5;

    p {
        margin-bottom: 16px;
    }

    @include breakpoint(sm) {
        font-size: 16px;
    }
}

.formular__layout {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-gap: 16px;
    padding-bottom: 40px;
    border-bottom: 1px solid colors.$divider;
    margin-bottom: 40px;

    @include breakpoint(sm) {
        display: block;
    }

    &--borderless {
        border-bottom: none;
    }

    &--clean {
        padding-bottom: 0;
        margin-bottom: 0;
    }
}

.formular__subheading {
    grid-column: span 3;
    text-transform: uppercase;

    @include breakpoint(sm) {
        margin-bottom: 24px;
    }
}

.formular__fields {
    grid-column: span 5;
}
