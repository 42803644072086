


























































































































.checkbox-group {
    padding-right: 32px;
}

.checkbox-group__label {
    margin-bottom: 16px;
}

.checkbox-group__checkbox:not(:last-child) {
    margin-bottom: 12px;
}

.checkbox-group__group {
    &--horizontal {
        display: flex;
    }

    &--horizontal > .form-field {
        margin-bottom: 0;
    }

    &--horizontal > .form-field:not(:last-of-type) {
        margin-right: 36px;
    }
}
