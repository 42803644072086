




































































































































@use "@/styles/mixins/breakpoint" as *;
@use "@/styles/variables/colors";

.faq__headline {
    margin-bottom: 24px;
}

.faq__questions {
    margin-bottom: 16px;
}
