










































































@use "@/styles/variables/colors";
@use "@/styles/mixins/breakpoint" as *;

.post-faq__headline {
    margin-bottom: 24px;
    font-size: 24px;
    line-height: 1.35;
    letter-spacing: -0.03em;
}

.post-faq__tiles {
    display: flex;

    @include breakpoint(sm) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 8px;
    }
}

.post-faq__tile {
    &:not(:last-child) {
        margin-right: 16px;

        @include breakpoint(sm) {
            margin-right: 0;
        }
    }
}
