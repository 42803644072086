





























































































































































































































@use "@/styles/mixins/breakpoint" as bp;
@use "@/styles/variables/colors";
@use "@/styles/variables/typography" as tp;


.court-steps-cards {
    --horizontal-indent: 20px;

    @include bp.mobile-md {
        --vertical-indent: 20px;
    }

    @include bp.desktop-md {
        --vertical-indent: 24px;
    }

    @include bp.mobile-md {
        margin: auto;
        max-width: 414px;
    }
}

.court-steps-card-list {
    margin-top: var(--vertical-indent);

    @include bp.mobile-md {
        display: flex;
        flex-direction: column;
        gap: var(--vertical-indent);
    }

    @include bp.desktop-md {
        display: flex;
        flex-direction: row;
        /* stylelint-disable-next-line */
        flex-wrap: wrap;
        align-items: stretch;
        gap: var(--vertical-indent) var(--horizontal-indent);
    }
}

.court-steps-card {
    @include bp.desktop-md {
        width: calc(50% - (var(--horizontal-indent) / 2))
    }
}

.court-steps-card__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 32px;
    height: 100%;
}

.court-steps-card__content {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @include bp.mobile-md {
        margin-top: var(--vertical-indent);
    }

    @include bp.desktop-md {
        margin-top: 32px;
    }
}

.court-steps-card__text-group {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.court-steps__help {
    @include bp.mobile-md {
        padding-top: var(--vertical-indent);
        padding-bottom: 40px;
    }

    @include bp.desktop-md {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}

.court-steps__help-link {
    @include bp.desktop-md {
        width: 336px;
        margin: auto;
    }
}

.court-steps__help-text {
    color: colors.$grey-4;
    font-family: tp.$font-family;
    font-weight: 400;
    text-align: center;
    margin-top: 8px;

    @include bp.mobile-md {
        font-size: tp.$font-size-small;
        line-height: 130%;
        letter-spacing: 0.12px;
    }

    @include bp.desktop-md {
        font-size: tp.$font-size-smaller;
        line-height: 114%;
    }
}
