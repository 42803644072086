













































































































































































@use "@/styles/variables/typography";
@use "@/styles/variables/colors";

.select {
    appearance: none;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24'%3E%3Cpath fill='currentColor' d='m12 15.713 6.01-6.01-1.413-1.415-4.597 4.6-4.596-4.6L5.99 9.702 12 15.713z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 12px center;

    &:focus,
    &--invalid {
        background-position: right 11px center;
    }
}
