@use "./mixins/breakpoint" as *;

.u-hidden-visually {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    overflow: hidden !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    border: 0 !important;
}

.u-cf {
    &::before,
    &::after {
        content: " ";
        display: table;
    }

    &::after {
        clear: both;
    }
}

.u-display-block {
    display: block !important;
}

.u-display-none {
    display: none !important;
}

.u-display-block-mobile {
    @include breakpoint(sm) {
        display: block !important;
    }
}

.u-display-none-mobile {
    @include breakpoint(sm) {
        display: none !important;
    }
}
