













































@use "@/styles/variables/colors";

.helper {
    font-size: 14px;
    line-height: 1.4;
}

.helper__text {
    color: colors.$placeholder;
    margin-top: 5px;

    &--error {
        color: colors.$danger;
    }
}

.helper__link {
    display: inline-block;
    padding-top: 8px;
    text-decoration: none;
}
