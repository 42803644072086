















































































@use "@/styles/variables/colors";
@use "@/styles/variables/grid";
@use "@/styles/mixins/breakpoint" as *;

.hero {
    display: flex;
    background-color: var(--bg-color);
    min-height: 500px;
    padding-bottom: grid.$space-3;
    background-image: url("@/images/hero.png");
    background-repeat: no-repeat;
    background-position-x: right;
    background-size: auto 100%;

    @include breakpoint(lg) {
        background-position-x: calc(100% + 240px);
    }

    @include breakpoint(md) {
        min-height: 398px;
        background-position-x: calc(100% + 252px);
    }

    @include breakpoint(sm) {
        padding-top: 174px;
        padding-bottom: 30px;
        background-position-x: right;
        background-position-y: top;
        background-size: auto 174px;
    }
}

.hero__container {
    position: relative;
    padding-top: 100px;

    @include breakpoint(md) {
        padding-top: 24px;
    }

    @include breakpoint(sm) {
        padding-top: 12px;
    }
}

.hero__container::before {
    content: "";
    position: absolute;
    bottom: -20px;
    left: calc((280px + 180px) * -1);
    width: 280px;
    height: 300px;
    background-image: url("@/images/hero-decoration.png");
    background-repeat: no-repeat;
    background-size: cover;

    @include breakpoint(lg) {
        display: none;
    }
}

.hero__content {
    max-width: 590px;

    @include breakpoint(md) {
        max-width: 328px;
    }

    @include breakpoint(sm) {
        max-width: 100%;
    }
}

.hero__heading {
    font-size: 48px;
    line-height: 1.2;
    margin-bottom: 16px;
    color: colors.$secondary-grey;

    @include breakpoint(md) {
        margin-bottom: 8px;
        font-size: 36px;
        letter-spacing: -0.02em;
    }

    @include breakpoint(sm) {
        margin-bottom: 12px;
    }
}

.hero__heading-accent {
    color: colors.$generaptor-brand-dark;
}

.hero__text {
    margin-bottom: 56px;
    font-size: 18px;
    line-height: 1.45;
    color: colors.$secondary-grey;

    @include breakpoint(md) {
        margin-bottom: 32px;
    }

    @include breakpoint(sm) {
        margin-bottom: 40px;
    }
}
