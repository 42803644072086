// см. https://www.figma.com/file/FQqvxpIt3cJ3sAq4x7gE53/%D0%94%D0%B8%D0%B7%D0%B0%D0%B9%D0%BD-%D1%81%D0%B8%D1%81%D1%82%D0%B5%D0%BC%D0%B0-%D0%9E%D0%98-(in-progress)?node-id=1%3A2

@use "sass:color";

$ovdinfo-brand: #f04e23;
$ovdinfo-brand-secondary: #f49864;
$ovdinfo-brown: #424242;

$dark: #2d2d2d;
$text: $dark;

$grey-7: #303033;
$grey-6: #535453;
$grey-5: #7e7e82;
$grey-4: #ababab;
$grey-3: #b2b5be;
$grey-2: #ebebf2;
$grey-1: #f7f7fa;
$white: #fff;
$orange-light: #fcdcd3;

$grey: $grey-5;
$secondary-grey: $grey-6;
$secondary-grey-light: $grey-4;
$secondary-grey-dark: $ovdinfo-brown;

$accent: $ovdinfo-brand;
$accent-light: $ovdinfo-brand-secondary;
$accent-dark: #e33d11; // не из дизайн-системы

$error: #da0c0c;
$danger: $error;
$warning: $ovdinfo-brand-secondary;
$valid: #0e9d25;

$border: $grey-4;
$placeholder: $grey-4;
$divider: $grey-3;

$generaptor-brand: #79a16a;
$primary-green: $generaptor-brand;
$secondary-green: $generaptor-brand; // так в макете лендинга 🤯
$generaptor-brand-deep: #599344;
$generaptor-brand-medium: #84a578;
$generaptor-brand-light: #edf2eb;
$generaptor-brand-dark: $generaptor-brand-deep;
$generaptor-brand-bg: $generaptor-brand-light;
$generaptor-brand-alpha: color.change($generaptor-brand, $alpha: 0.4); // не из дизайн-системы
$generaptor-brand-bg-alpha: color.change($generaptor-brand-bg, $alpha: 0.5); // не из дизайн-системы
