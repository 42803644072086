@use "./variables/grid";
@use "./variables/typography";
@use "./variables/colors";
@use "./mixins/breakpoint" as *;

// Fields (text inputs, select dropdowns)

%form-field-control-styles {
    display: flex;
    flex: 1;
    width: 100%;
    min-width: 80px;
    height: grid.$space-6;
    padding: 0 15px;
    color: currentcolor;
    background-color: transparent;
    border: 1px solid colors.$border;
    border-radius: 6px;
    outline: none;
    font-family: typography.$font-family;
    font-size: typography.$font-size-normal;
    appearance: none;
}

%form-field-control-focus-styles {
    outline: none;
    border-color: colors.$accent-light;
}

%form-field-control-invalid-styles {
    border-color: colors.$danger;
}

.form-field__label {
    display: block;
    // grid-column: span 3;
    margin-bottom: 4px;
    font-size: typography.$font-size-normal;
    line-height: 1.5;
}

.form-field__wrapper {
    // grid-column: span 5;
    display: flex;
    position: relative;
}

.form-field__icon-invalid-wrapper {
    height: grid.$space-6;
    display: flex;
    align-items: center;
    position: absolute;
    right: -#{grid.$space-4};
    top: 0;

    @include breakpoint(sm) {
        display: none;
    }
}

.form-field__icon-invalid {
    visibility: hidden;
    padding-left: 4px;
    padding-right: 4px;
    height: grid.$space-3;
    width: grid.$space-4;
    color: colors.$danger;

    &.is-visible {
        visibility: visible;
    }
}

.form-field__control-wrapper {
    width: 100%;
}

.form-field__control {
    @extend %form-field-control-styles;

    &::placeholder {
        color: colors.$placeholder;
    }

    &:focus {
        @extend %form-field-control-focus-styles;
    }

    &--invalid {
        @extend %form-field-control-invalid-styles;
    }

    &--textarea {
        padding-top: 13px;
        padding-bottom: 13px;
        line-height: 1.5;
        resize: none;
    }
}

.form-field__helper-wrapper {
    padding-top: 4px;
}

// Selectors (checkboxes, radiobuttons)

.form-field__selector-wrapper {
    grid-column: span 5;
}

.form-field__selector {
    position: relative;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
}

.form-field__selector-label {
    margin-left: 12px;
    line-height: 1.5;
}

.required {
    color: colors.$danger;
}
