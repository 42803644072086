








@use "@/styles/variables/colors";

.spinner {
    display: flex;
}

.spinner__dot {
    width: 10px;
    height: 10px;
    background-color: colors.$white;
    border-radius: 100%;

    &:not(:last-child) {
        margin-right: 12px;
    }

    &--middle {
        animation: middle-animation 0.3s infinite alternate ease-in-out;
    }

    &--edge {
        animation: edge-animation 0.3s infinite alternate ease-in-out;
    }
}

@keyframes middle-animation {
    0% {
        opacity: 1;
        transform: translateY(-3px);
    }

    100% {
        opacity: 0.6;
        transform: translateY(3px);
    }
}

@keyframes edge-animation {
    0% {
        opacity: 0.6;
        transform: translateY(3px);
    }

    100% {
        opacity: 1;
        transform: translateY(-3px);
    }
}
