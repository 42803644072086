








































@use "sass:color";
@use "@/styles/variables/typography";
@use "@/styles/variables/colors";
@use "@/styles/variables/grid";
@use "@/styles/variables/layout";
@use "@/styles/mixins/breakpoint" as *;

.modal {
    @include desktop {
        display: flex;
        align-items: center;
        position: fixed;
        z-index: 2147483647;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &::v-deep .overlay__backdrop {
        background-color: color.change(colors.$text, $alpha: 0.5);
    }

    &::v-deep .overlay__body-wrapper {
        display: flex;
        max-height: 100%;
        margin: 0 auto;

        @include desktop {
            padding: grid.$space-3;
        }
    }

    &::v-deep .overlay__body {
        position: relative;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        margin: 0 auto;
        max-width: calc(1100px + #{grid.$space-3} * 2);
        background-color: colors.$white;

        @include mobile {
            padding-bottom: grid.$space-3;
        }

        @include desktop {
            padding-top: grid.$space-6;
            padding-bottom: grid.$space-6;
            border-radius: grid.$space-1;
        }
    }

    .modal__content {
        overflow: auto;
        margin-left: grid.$space-2;
        margin-right: grid.$space-2;

        @include desktop {
            margin-left: grid.$space-6;
            margin-right: grid.$space-6;
        }
    }

    &.overlay-transition-enter-active,
    &.overlay-transition-leave-active {
        &,
        &::v-deep .overlay__body {
            transition: opacity .3s ease, transform .3s ease;
        }
    }

    &.overlay-transition-enter,
    &.overlay-transition-leave-to {
        opacity: 0;

        &::v-deep .overlay__body {
            transform: translateY(10px);
            opacity: 0;
        }
    }
}
