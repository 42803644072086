// Styles for formatted HTML content

.rich-text {
    line-height: 1.5;

    ul {
        list-style-type: disc;
        padding-left: 16px;
    }

    ol {
        list-style-type: decimal;
        padding-left: 16px;
    }

    p:not(:last-child),
    ul:not(:last-child),
    ol:not(:last-child) {
        margin-bottom: 16px;
    }

    li:not(:last-child) {
        margin-bottom: 8px;
    }
}
