































































































































@use "@/styles/variables/grid";

.form-field__selector {
    margin-right: grid.$space-1 + grid.$space-05;
}

.radio-group {
    padding-right: grid.$space-4;
}

.radio-group__label {
    margin-bottom: grid.$space-2;
}

.radio-group__radio {
    margin-bottom: grid.$space-1 + grid.$space-05;
}

.radio-group__group {
    display: flex;
    flex-direction: column;

    & > .form-field {
        margin-bottom: grid.$space-2;
    }

    &--horizontal {
        flex-direction: row;

        .radio-group__radio {
            margin-bottom: 0;
        }
    }

    &--horizontal > .form-field {
        margin-bottom: 0;
    }

    &--horizontal > .form-field:not(:last-of-type) {
        margin-right: grid.$space-4;
    }
}
