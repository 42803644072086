



















































































































































































































@use "@/styles/variables/grid";

.formular__fields {
    .form-field {
        &:not(.form-field--first) {
            margin-top: grid.$space-3;
        }
    }
}

.form-field-wrapper {
    $fade-in-out-duration: 300ms;

    transition: opacity $fade-in-out-duration;

    &.conditional-enter-active {
        opacity: 0;
    }

    &.conditional-enter-to {
        opacity: 1;
    }

    &.conditional-leave-active {
        opacity: 0;
    }
}
