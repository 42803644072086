@use "./mixins/breakpoint" as *;
@use "./variables/colors";

.section {
    &--padding-top-1 {
        padding-top: 40px;

        @include breakpoint(sm) {
            padding-top: 32px;
        }
    }

    &--padding-bottom-1 {
        padding-bottom: 40px;

        @include breakpoint(sm) {
            padding-bottom: 32px;
        }
    }

    &--padding-top-2 {
        padding-top: 90px;

        @include breakpoint(sm) {
            padding-top: 40px;
        }
    }

    &--padding-bottom-2 {
        padding-bottom: 90px;

        @include breakpoint(sm) {
            padding-bottom: 40px;
        }
    }

    &--padding-top-3 {
        padding-top: 150px;

        @include breakpoint(md) {
            padding-top: 80px;
        }

        @include breakpoint(sm) {
            padding-top: 50px;
        }
    }

    &--padding-bottom-3 {
        padding-bottom: 150px;

        @include breakpoint(md) {
            padding-bottom: 80px;
        }

        @include breakpoint(sm) {
            padding-bottom: 50px;
        }
    }

    &.section--padding-80 {
        padding-top: 80px;

        @include mobile-md {
            padding-bottom: 40px;
        }

        @include desktop-md {
            padding-bottom: 80px;
        }
    }

    &--colored {
        background-color: colors.$generaptor-brand-bg;
    }

    &--slider {
        @include breakpoint(sm) {
            overflow-x: hidden;
        }
    }
}
