

























































































@use "@/styles/variables/grid";
@use "@/styles/mixins/breakpoint";
@import "./common";

.formular__result--status-pic {
    margin-top: grid.$space-10 + grid.$space-1;

    @include breakpoint.desktop {
        margin-top: grid.$space-8 + grid.$space-1;
    }
}
