


























@use "@/styles/variables/colors";

.user-avatar {
    max-width: 60px;
    max-height: 60px;
    overflow: hidden;
    border-radius: 50%;
    background: colors.$white;
    border: 1px solid colors.$generaptor-brand;

    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
