





























@use "@/styles/mixins/breakpoint" as bp;
@use "@/styles/variables/colors";
@use "@/styles/variables/typography" as tp;

.ui-paragraph {
    color: colors.$secondary-grey;
    font-family: tp.$font-family;
    font-weight: 400;

    @include bp.mobile-md {
        font-size: tp.$font-size-normal;
        line-height: 24px;
    }

    @include bp.desktop-md {
        // TODO: возможно, надо переопределять значение $font-size-normal для десктопа
        font-size: 20px;
        line-height: 28px;
    }

    &.ui-paragraph--pale {
        color: colors.$grey-5;
    }

    &.ui-paragraph--center {
        text-align: center;
    }

    em {
        font-weight: 500;
        font-style: normal;
    }
}
