












































































@use "@/styles/mixins/breakpoint";
@use "@/styles/variables/grid";

.email-confirmation {
    flex-grow: 1;
    margin: grid.$space-2 auto grid.$space-4;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include breakpoint.desktop {
        width: 600px;
    }
}

.email-confirmation__pic {
    width: 100%;
    margin-bottom: grid.$space-5 + grid.$space-05;
}

.email-confirmation__action {
    border-bottom-style: solid;

    &:not(:last-child) {
        margin-bottom: grid.$space-2;
    }
}
