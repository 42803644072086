





















































































































































































































































































@use "sass:color";
@use "@/styles/variables/typography";
@use "@/styles/variables/colors";
@use "@/styles/form-controls" as *;

.autocomplete::v-deep {
    .vs__dropdown-toggle {
        @extend %form-field-control-styles;

        height: auto;
        min-height: 48px;
    }

    .vs__selected-options {
        padding: 0;
        display: flex;
    }

    &.vs--single .vs__selected-options {
        height: auto;
    }

    .vs__selected {
        height: 42px;
        padding: 8px 16px;
        font-size: 16px;
        background-color: color.change(#e39d72, $alpha: 0.5);
        border: 0;
        border-radius: 9px;
        color: currentcolor;
        margin: 2px;
    }

    &.vs--single .vs__selected {
        height: 100%;
        padding: 0;
        margin: 0;
        background-color: transparent;
    }

    &.vs--single.vs--open .vs__selected {
        display: none;
    }

    .vs__deselect {
        margin-left: 16px;
        color: #ac6133;
    }

    .vs__search {
        margin: 0;
        padding: 0;
        z-index: initial;

        &::placeholder {
            color: colors.$placeholder;
        }
    }

    .vs__actions {
        padding: 0;
    }

    .vs__open-indicator {
        transform: none;
    }

    .vs__dropdown-menu {
        top: 100%;
        z-index: 1;
    }

    .vs__dropdown-option {
        white-space: normal;
        padding: 4px 20px;
    }

    .vs__dropdown-option--highlight {
        background: #e39d72;
    }

    &.autocomplete--invalid .vs__dropdown-toggle {
        @extend %form-field-control-invalid-styles;
    }

    &.vs--open .vs__dropdown-toggle {
        @extend %form-field-control-focus-styles;
    }

    &.vs--disabled .vs__search,
    &.vs--disabled .vs__open-indicator,
    &.vs--disabled .vs__clear {
        background-color: transparent;
    }
}
