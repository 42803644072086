@use "sass:map";
@use "../variables/breakpoints" as *;

/// Generate media query
///
/// @param {String} $key
/// @param {String} $direction ["<"]
@mixin breakpoint($key, $direction: "<") {
    $breakpoint: map.get($breakpoints, $key);

    @if $breakpoint {
        @if $direction == "<" {
            @media (max-width: #{$breakpoint}) {
                @content;
            }
        } @else {
            @media (min-width: #{$breakpoint + 1px}) {
                @content;
            }
        }
    } @else {
        @content;
    }
}

@mixin mobile {
    @include breakpoint(sm) {
        @content;
    }
}

@mixin desktop {
    @include breakpoint(sm, ">") {
        @content;
    }
}

@mixin mobile-md {
    @include breakpoint(md, "<") {
        @content;
    }
}

@mixin desktop-md {
    @include breakpoint(md, ">=") {
        @content;
    }
}
