
















































































@use "@/styles/variables/colors";
@use "@/styles/variables/grid";

.document-selector {
    max-width: 297px;
}

.document-selector__select {
    margin-bottom: grid.$space-3;
}
