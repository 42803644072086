






@use "@/styles/mixins/breakpoint" as bp;
@use "@/styles/variables/colors";
@use "@/styles/variables/typography" as tp;

.ui-h2 {
    color: colors.$secondary-grey;
    font-family: tp.$font-family;
    font-weight: 500;

    @include bp.mobile-md {
        font-size: 22px;
        line-height: 145%;
        letter-spacing: -0.44px;
    }

    @include bp.desktop-md {
        font-size: 24px;
        line-height: 135%;
        letter-spacing: -0.72px;
    }
}
