@use "./mixins/breakpoint" as *;


$container-padding-sm-horizontal: 16px;
$container-padding-sm: 0 $container-padding-sm-horizontal;
$container-max-width: 1090px;


@mixin container-md-lg {
    width: 100%;
    max-width: $container-max-width;
    margin-right: auto;
    margin-left: auto;
    padding: 0 30px;
}

.container {
    @include container-md-lg;

    @include breakpoint(sm) {
        padding: $container-padding-sm;
    }
}

.container__grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 16px;

    @include breakpoint(md) {
        display: block;
    }
}
