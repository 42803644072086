@use "@/styles/mixins/breakpoint";

.formular__result__btn {
    @include breakpoint.mobile {
        width: 100%;
        justify-content: center;
    }
}

.formular__result__preformatted {
    overflow-wrap: break-word;
}
