














































@use "@/styles/variables/grid";
@use "@/styles/variables/colors";

.selector-block {
    display: block;
    width: 100%;
}

.button-selector {
    cursor: pointer;
    background: colors.$white;
    border: 1px solid colors.$generaptor-brand-medium;
    height: grid.$space-10;
    color: colors.$ovdinfo-brown;
    font-size: grid.$space-3;
    font-weight: 500;
    line-height: grid.$space-4;
    letter-spacing: -0.02em;
    text-align: center;
    align-items: center;
}

.button-selector:hover {
    background: colors.$generaptor-brand-medium;
}

.button-selector__active {
    background: colors.$generaptor-brand-light;
}
