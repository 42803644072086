




























































@use "@/styles/mixins/breakpoint";
@use "@/styles/variables/colors";

.auth-widget__signin-btn {
    @include breakpoint.mobile {
        .auth-widget--login-pending & {
            display: none;
        }
    }

    &.auth-widget__signin-btn {
        --text-color: #{colors.$white};
        --text-color-active: #{colors.$text};

        @include breakpoint.mobile {
            --border-color: transparent;
        }

        @include breakpoint.desktop {
            --border-color: #{colors.$white};
        }
    }
}
