





















































@use "@/styles/mixins/breakpoint" as bp;
@use "@/styles/variables/colors";

.espch {
    background: colors.$grey-1;

    @include bp.mobile-md {
        padding: 40px 0;
    }

    @include bp.desktop-md {
        padding: 80px 0;
    }
}

.espch-coumns {
    display: flex;
    gap: 40px;
    align-items: center;

    @include bp.mobile-md {
        flex-direction: column;
    }

    @include bp.desktop-md {
        width: 650px;
        margin: auto;
        flex-direction: row;
    }
}

.espch-columns__left {
    width: 174px;
}

.espch-mail-img {
    width: 100%;
}

.espch-columns__right {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @include bp.desktop-md {
        width: 440px;
    }
}

.espch-mailto-wrapper {
    @include bp.desktop-md {
        align-self: flex-start;
    }

    &::v-deep a {
        color: colors.$secondary-grey;
    }
}
