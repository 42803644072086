




















































































































@use "@/styles/mixins/breakpoint" as bp;
@use "@/styles/variables/colors";
@use "@/styles/variables/typography" as tp;

.cs-document {
    @include bp.mobile-md {
        margin: 80px auto 0;
        max-width: 414px;
    }

    @include bp.desktop-md {
        margin-top: 52px;
    }
}

.cs-document__title {
    color: colors.$secondary-grey;
    font-family: tp.$font-family;
    font-weight: 500;

    @include bp.mobile-md {
        margin-bottom: 20px;
        font-size: 28px;
        line-height: 130%;
        letter-spacing: -0.56px;
    }

    @include bp.desktop-md {
        margin-bottom: 40px;
        font-size: 36px;
        line-height: 140%;
        letter-spacing: -0.72px;
    }
}

.cs-document__card-content {
    @include bp.desktop-md {
        position: relative;
        max-width: 560px;
    }
}

.cs-document__generaptor {
    @include bp.mobile-md {
        display: none;
    }

    @include bp.desktop-md {
        position: absolute;
        left: 106%;
        top: -40px;
    }
}

.cs-document__generation-steps {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 20px;
}

.cs-document__action {
    margin-top: 32px;

    @include bp.desktop-md {
        width: 300px;
    }
}
