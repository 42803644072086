









































































@use "@/styles/variables/colors";
@use "@/styles/form-controls" as *;

.radio__input {
    position: relative;
    top: 0;
    left: 0;
    width: 28px;
    height: 28px;
    flex-shrink: 0;
    border: 0;
    cursor: pointer;
    appearance: none;
}

.radio__input::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 28px;
    height: 28px;
    border: 1px solid colors.$border;
    border-radius: 50%;

    .radio--invalid & {
        @extend %form-field-control-invalid-styles;
    }
}

.radio__input:checked::after {
    content: "";
    position: absolute;
    top: calc(50% - 5px);
    left: calc(50% - 5px);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: colors.$text;
}
