






















































































































@use "@/styles/variables/colors";

.checkbox__input {
    position: relative;
    top: 0;
    left: 0;
    width: 32px;
    height: 32px;
    border: 0;
    flex-shrink: 0;
    cursor: pointer;
    appearance: none;
}

.checkbox__input::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 32px;
    height: 32px;
    border: 1px solid colors.$border;
    border-radius: 6px;
}

.checkbox__input:checked::before {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24'%3E%3Cpath fill='currentColor' d='m9.348 17.834.177.177.177-.177v-.001l1.414-1.413L19.6 7.934l.177-.177-.177-.177-1.414-1.414-.177-.177-.177.177-8.307 8.307-3.36-3.357-.177-.176-.177.176-1.414 1.414-.177.177.177.177 4.95 4.95z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
}
