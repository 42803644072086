












@use "@/styles/variables/grid";

.tabs-container {
    width: 100%;
    display: flex;
    margin-top: grid.$space-6;
    margin-bottom: grid.$space-4;
}
