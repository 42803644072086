









































































































































































































































































































































@use "@/styles/mixins/centering" as *;
@use "@/styles/mixins/reset" as *;
@use "@/styles/variables/grid";

.date-time-picker {
    display: block;

    &--has-time {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: grid.$space-2;
    }

    &::v-deep .date-time-picker__input-wrapper {
        position: relative;
    }

    &::v-deep .date-time-picker__toggle-popover {
        @extend %vertically-centered;
        @extend %button-reset;

        position: absolute;
        right: 0;
        padding: grid.$space-1 + grid.$space-05;

        img {
            display: block;
        }
    }

    &::v-deep .vc-date {
        display: none !important;
    }
}

.date-time-picker__date-picker {
    grid-column: span 3;
}

.date-time-picker__time-picker {
    grid-column: span 2;
}
