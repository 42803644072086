






























































































@use "@/styles/mixins/breakpoint" as *;
@use "@/styles/variables/colors";
@use "@/styles/variables/grid";

.faq__question {
    background-color: colors.$generaptor-brand-bg;
    border-radius: 8px;
    transition: background-color 0.15s ease;

    &:not(:last-child) {
        margin-bottom: 8px;
    }

    &:hover {
        background-color: colors.$generaptor-brand-bg-alpha;
    }

    &--active {
        &:hover {
            background-color: colors.$generaptor-brand-bg;
        }
    }

    @media (hover: none) {
        &:hover {
            background-color: colors.$generaptor-brand-bg;
        }
    }
}

.faq__question-title {
    --faq-question-color: #{colors.$secondary-grey};

    display: flex;
    align-items: center;
    padding: grid.$space-3 grid.$space-6;
    width: 100%;
    min-height: 32px;
    background: transparent;
    border: 0;
    color: colors.$secondary-grey;
    font-size: 24px;
    line-height: 1.35;
    font-weight: 500;
    letter-spacing: -0.03em;
    text-align: left;
    cursor: pointer;
    flex-shrink: 0;
    overflow: hidden;
    appearance: none;

    @include breakpoint(md) {
        padding: grid.$space-2;
        font-size: 18px;
        line-height: 26px;
    }
}

.faq__question-title-text {
    position: relative;
    padding-left: grid.$space-6;

    @include breakpoint(md) {
        padding-left: grid.$space-4;
    }
}

.faq__question-plus-icon {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 4px;
    left: 0;

    @include breakpoint(md) {
        width: 16px;
        height: 16px;
        top: 5px;
    }

    &::before,
    &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 3px;
        background-color: var(--faq-question-color);
        top: calc(50% - 3px / 2);
        left: 0;
        transition: transform 150ms ease-out;

        @include breakpoint(md) {
            height: 2px;
            top: calc(50% - 1px);
        }
    }

    &::after {
        transform: rotate(90deg);
    }
}

.faq__question-plus-icon--active {
    &::after {
        transform: rotate(0deg);
    }
}

.faq__question-text-wrapper {
    padding: 0 45px;
    border-radius: 8px;
    color: colors.$secondary-grey;
    transition: height 150ms ease-out;
    height: 0;
    overflow: hidden;

    @include breakpoint(md) {
        padding: 0 16px;
    }
}

.faq__question-text {
    padding: 0 0 24px 48px;

    @include breakpoint(md) {
        padding: 0 0 16px 32px;
        font-size: 16px;
        line-height: 1.5;
    }
}
