











@use "@/styles/variables/colors";

.button-tip {
    display: flex;
    align-items: center;
}

.button-tip__hint {
    color: colors.$placeholder;
    font-size: 14px;
    margin-left: 24px;
}
