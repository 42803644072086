

































































@use "@/styles/variables/grid";
@use "@/styles/mixins/breakpoint";
@import "./common";

.formular__result--status-pic {
    margin-top: grid.$space-8;

    @include breakpoint.desktop {
        margin-top: grid.$space-4;
    }
}
